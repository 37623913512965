// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-h2 {
    text-transform: uppercase;
    font-size: 1rem;
    padding: 20px;
    padding-left: 10px;
    text-align: center;
}

.google-map {
    width: 100%;
    height: 60vh;
}

.pin {
    display: flex;
    align-items: center;
    width: 180px;
    color: blue;
}

.pin-icon {
    font-size: 4rem;
}

.pin-text {
    font-size: 1.3em;
}

@media screen and (min-width: 799px) {
    .google-map {
        height: 80vh;
    }

    .map-h2 {
        font-size: 1.3rem;
        font-weight: 400;
    }

    .pin {
        width: 15vw;
    }

    .pin-icon {
        font-size: 10vw;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/LocationPin/LocationPin.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,YAAY;IAChB;;IAEA;QACI,iBAAiB;QACjB,gBAAgB;IACpB;;IAEA;QACI,WAAW;IACf;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".map-h2 {\n    text-transform: uppercase;\n    font-size: 1rem;\n    padding: 20px;\n    padding-left: 10px;\n    text-align: center;\n}\n\n.google-map {\n    width: 100%;\n    height: 60vh;\n}\n\n.pin {\n    display: flex;\n    align-items: center;\n    width: 180px;\n    color: blue;\n}\n\n.pin-icon {\n    font-size: 4rem;\n}\n\n.pin-text {\n    font-size: 1.3em;\n}\n\n@media screen and (min-width: 799px) {\n    .google-map {\n        height: 80vh;\n    }\n\n    .map-h2 {\n        font-size: 1.3rem;\n        font-weight: 400;\n    }\n\n    .pin {\n        width: 15vw;\n    }\n\n    .pin-icon {\n        font-size: 10vw;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
