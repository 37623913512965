// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__footerOverlay {
    width: 100%;
    height: 100%;
    z-index: -1;

    display: flex;
    flex-direction: column;
    position: absolute;
}

.app__footerOverlay-black {
    height: 25%;
    background: var(--color-main);
}

.app__footerOverlay-img {
    height: 75%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/FooterOverlay.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,WAAW;;IAEX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,6BAA6B;AACjC;;AAEA;IACI,WAAW;AACf","sourcesContent":[".app__footerOverlay {\n    width: 100%;\n    height: 100%;\n    z-index: -1;\n\n    display: flex;\n    flex-direction: column;\n    position: absolute;\n}\n\n.app__footerOverlay-black {\n    height: 25%;\n    background: var(--color-main);\n}\n\n.app__footerOverlay-img {\n    height: 75%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
