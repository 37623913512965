// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto+Flex:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --font-base: 'Josefin Sans', serif;
  --font-alt: 'Open Sans', sans-serif;

  --color-golden: #d0b033;
  /*--color-black: #0C0C0C;*/
  --color-black: #000000;
  --color-main: rgb(0, 0, 0);
  --color-gray: #545454;
  --color-crimson: #F5EFDB;
  --color-grey: #aeaaaa;
  --color-white: #FFFFFF;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
}

.slide-bottom {
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-bottom {
  0% {
    transform: translateY(-25%);
  }
  100% {
    transform: translateY(0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAGA;EACE,kCAAkC;EAClC,mCAAmC;;EAEnC,uBAAuB;EACvB,0BAA0B;EAC1B,sBAAsB;EACtB,0BAA0B;EAC1B,qBAAqB;EACrB,wBAAwB;EACxB,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,UAAU;EACV,SAAS;EACT,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;CAEC,0EAA0E;AAC3E;;AAaA;EACE;IAEU,2BAA2B;EACrC;EACA;IAEU,wBAAwB;EAClC;AACF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap');\n\n:root {\n  --font-base: 'Josefin Sans', serif;\n  --font-alt: 'Open Sans', sans-serif;\n\n  --color-golden: #d0b033;\n  /*--color-black: #0C0C0C;*/\n  --color-black: #000000;\n  --color-main: rgb(0, 0, 0);\n  --color-gray: #545454;\n  --color-crimson: #F5EFDB;\n  --color-grey: #aeaaaa;\n  --color-white: #FFFFFF;\n}\n\n* {\n  box-sizing: border-box;\n  padding: 0;\n  margin: 0;\n  scroll-behavior: smooth;\n}\n\na {\n  color: unset;\n  text-decoration: none;\n}\n\n.slide-bottom {\n  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;\n\tanimation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;\n}\n\n@-webkit-keyframes slide-bottom {\n  0% {\n    -webkit-transform: translateY(-25%);\n            transform: translateY(-25%);\n  }\n  100% {\n    -webkit-transform: translateY(0);\n            transform: translateY(0);\n  }\n}\n\n@keyframes slide-bottom {\n  0% {\n    -webkit-transform: translateY(-25%);\n            transform: translateY(-25%);\n  }\n  100% {\n    -webkit-transform: translateY(0);\n            transform: translateY(0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
