import images from './images';

const wines = [
  {
    title: 'Winery Castellani S.p.A',
    price: '$16.99',
    tags: 'Bottle',
    year: 2019,
    country: 'Italy',
    region: 'Maremma Toscana',
  },
  {
    title: 'Winery Castellani S.p.A',
    price: '$16.99',
    tags: 'Bottle',
    year: 2019,
    country: 'Italy',
    region: 'Maremma Toscana',
  },
  {
    title: 'Winery Castellani S.p.A',
    price: '$16.99',
    tags: 'Bottle',
    year: 2019,
    country: 'Italy',
    region: 'Maremma Toscana',
  },
  {
    title: 'Winery Castellani S.p.A',
    price: '$16.99',
    newPrice: '$14.99',
    tags: 'Bottle',
    year: 2019,
    country: 'Italy',
    region: 'Maremma Toscana',
  },
  {
    title: 'Winery Castellani S.p.A',
    price: '$56.99',
    tags: 'Bottle',
    year: 2015,
    country: 'Italy',
    region: 'Maremma Toscana',
  },
];

const awards = [
  {
    imgUrl: images.barolo,
    title: 'Barolo DOCG',
    origin: 'Novello and Barolo, Piedmont, Italy',
    grapevariety: '100% Nebbiolo',
    soil: 'Calcareous clay',
    ageing: '2 months in stainless steel tanks, 24 to 30 months in oak barrels and 6 months in steel tanks',
    description: 'Barolo is a powerful and full-bodied red wine, often referred to as "the king of wines" for its prestigious reputation. Made from Nebbiolo grapes, it showcases the best of Piedmontese winemaking.',
    link: 'https://drive.google.com/file/d/1u0khRA3ICGxiuk1mZ8_OMcctCalKu-3h/view?usp=drive_link',
  },
  {
    imgUrl: images.barbaresco,
    title: 'Barbaresco Riserva DOCG',
    origin: 'Neive and Treiso, Piedmont, Italy',
    grapevariety: '100% Nebbiolo',
    soil: 'Limestone, clay, marlstone',
    ageing: '2 month in stainless steel tanks, 14-15 month in oak casks, 6 month in stainless steel tanks ',
    description: 'Barbaresco Riserva DOCG is complex Italian red wine with floral (rose, violet) and dark fruit (plum) aromas, smooth taste, long finish. Pairs with red meat, cheese or enjoyed alone.',
    link: 'https://drive.google.com/file/d/1Kj8K-gRsFA5OD6lsb5JWu5r0F7Tn60KU/view?usp=drive_link',
  },
  {
    imgUrl: images.gavi1,
    title: 'Gavi del Comune di Gavi DOCG',
    origin: 'Gavi, Piedmont, Italy',
    grapevariety: '100% Cortese',
    soil: 'Calcareous marlstone',
    ageing: '2 months in stainless steel tanks and 2 months in bottle',
    description: 'The Bric Sassi, is a perfect union between the territory and the climate. The result is a complex wine, worked traditionally using modern techniques. A wine of excellent finesse and complexity, with a surprisingly long life.',
    link: 'https://drive.google.com/file/d/1O4qXCKyb6oXC6vlwy4GRhSdRIVw7pBFr/view?usp=drive_link',
  },
  {
    imgUrl: images.nebbiolo,
    title: 'Langhe DOC Nebbiolo',
    origin: 'Novello and Barolo, Piedmont, Italy',
    grapevariety: '100% Nebbiolo',
    soil: 'Calcareous clay',
    ageing: '2 month in stainless steel tanks, 6-8 month in oak casks, 6 month in stainless steel tanks, 6 month in the bottle',
    description: 'Langhe Nebbiolo DOC: Red wine with aged Nebbiolo grapes. Rich ruby color, complex aromas of red fruit and spice. Full-bodied with smooth tannins, pairs well with red meat and game.',
    link: 'https://drive.google.com/file/d/1idteOrrYSc9LyOgkoSGS8qUuXePHBb4c/view?usp=drive_link',
  },
  {
    imgUrl: images.barberaasti,
    title: 'Barbera d Asti DOCG',
    origin: 'Barbera d Asti, Piedmont, Italy',
    grapevariety: '100% Barbera',
    soil: 'Gray clay',
    ageing: '2 month in stainless steel tanks, 6-8 month in oak casks, 6 month in stainless steel tanks, 6 month in the bottle ',
    description: 'Barbera d Asti DOCG: Renowned Piedmont red wine made from Barbera grapes. Thrives in Asti and Nizza Monferrato. Produces both young, fruity wines and richer aged styles.',
    link: 'https://drive.google.com/file/d/1pkzz4ZhDGDKyEnJQQM4Kuyg4LE8x_mZG/view?usp=drive_link',
  },
  {
    imgUrl: images.dolcetto,
    title: 'Dolcetto d Alba DOC',
    origin: 'Neviglie and Barolo, Piedmont, Italy',
    grapevariety: '100% Dolcetto',
    soil: 'Lime gray clay',
    ageing: 'stainless steel tanks then in the bottle for 8-10 month',
    description: 'Dolcetto d Alba DOC: Easy-drinking red wine from Langhe. Fruity with dry finish, smooth, balanced, and slightly spicy. Perfect with appetizers, pasta, light meat, even some fish. Enjoyed slightly chilled in summer.',
    link: 'https://drive.google.com/file/d/1QyhCc4ebnWl1LC7dcjMtWQOp8vZ5FQP9/view?usp=drive_link',
  },
];

export default { wines, awards };
