// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__header {
    background-color: var(--color-main);
}

.app__header-h1 {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-weight: 700;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 117px;
    font-size: 90px;
}

.app__header-img img {
    width: 80%;
}

@media screen and (min-width: 2000px) {
    .app__header-h1 {
        font-size: 180px;
        line-height: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__header-h1 {
        font-size: 90px;
    }
}

@media screen and (max-width: 650px) {
    .app__header-h1 {
        font-size: 70px;
        line-height: 87px;
    }
}

@media screen and (max-width: 450px) {
    .app__header-h1 {
        font-size: 50px;
        line-height: 70px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/container/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;AACvC;;AAEA;IACI,6BAA6B;IAC7B,0BAA0B;IAC1B,gBAAgB;IAChB,sBAAsB;IACtB,0BAA0B;IAC1B,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI;QACI,gBAAgB;QAChB,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,eAAe;QACf,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,eAAe;QACf,iBAAiB;IACrB;AACJ","sourcesContent":[".app__header {\n    background-color: var(--color-main);\n}\n\n.app__header-h1 {\n    font-family: var(--font-base);\n    color: var(--color-golden);\n    font-weight: 700;\n    letter-spacing: 0.04em;\n    text-transform: capitalize;\n    line-height: 117px;\n    font-size: 90px;\n}\n\n.app__header-img img {\n    width: 80%;\n}\n\n@media screen and (min-width: 2000px) {\n    .app__header-h1 {\n        font-size: 180px;\n        line-height: 210px;\n    }\n}\n\n@media screen and (max-width: 1150px) {\n    .app__header-h1 {\n        font-size: 90px;\n    }\n}\n\n@media screen and (max-width: 650px) {\n    .app__header-h1 {\n        font-size: 70px;\n        line-height: 87px;\n    }\n}\n\n@media screen and (max-width: 450px) {\n    .app__header-h1 {\n        font-size: 50px;\n        line-height: 70px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
