// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__newsletter {
    padding: 2rem 4rem;
    border: 1px solid var(--color-golden);
    background: var(--color-main);
}

.app__newsletter-heading {
    text-align: center;
}

.app__newsletter-input {
    flex-direction: row;
    margin-top: 3rem;
}

.app__newsletter-input input {
    width: 620px;
    border: 1px solid var(--color-golden);
    border-radius: 5px;
    font-size: 1rem;
    font-family: var(--font-base);
    color: var(--color-black);
    
    margin-right: 2rem;
    padding: 0.75rem 1rem;
    background: var(--color-main);
}

.app__newsletter-input button {
    width: max-content;
}

@media screen and (min-width: 2000px) {
    .app__newsletter-input input {
        font-size: 2rem;
    }
}

@media screen and (max-width: 990px) {
    .app__newsletter-input {
        flex-direction: column;
        width: 100%;
    }

    .app__newsletter-input input {
        margin: 0 0 2rem 0;
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    .app__newsletter {
        padding: 2rem 0;
        border: none;
    }
}

@media screen and (max-width: 300px) {
    .app__newsletter-heading h1 {
        font-size: 32px;
        line-height: 50px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Newsletter.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qCAAqC;IACrC,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,qCAAqC;IACrC,kBAAkB;IAClB,eAAe;IACf,6BAA6B;IAC7B,yBAAyB;;IAEzB,kBAAkB;IAClB,qBAAqB;IACrB,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,sBAAsB;QACtB,WAAW;IACf;;IAEA;QACI,kBAAkB;QAClB,WAAW;IACf;AACJ;;AAEA;IACI;QACI,eAAe;QACf,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,eAAe;QACf,iBAAiB;IACrB;AACJ","sourcesContent":[".app__newsletter {\n    padding: 2rem 4rem;\n    border: 1px solid var(--color-golden);\n    background: var(--color-main);\n}\n\n.app__newsletter-heading {\n    text-align: center;\n}\n\n.app__newsletter-input {\n    flex-direction: row;\n    margin-top: 3rem;\n}\n\n.app__newsletter-input input {\n    width: 620px;\n    border: 1px solid var(--color-golden);\n    border-radius: 5px;\n    font-size: 1rem;\n    font-family: var(--font-base);\n    color: var(--color-black);\n    \n    margin-right: 2rem;\n    padding: 0.75rem 1rem;\n    background: var(--color-main);\n}\n\n.app__newsletter-input button {\n    width: max-content;\n}\n\n@media screen and (min-width: 2000px) {\n    .app__newsletter-input input {\n        font-size: 2rem;\n    }\n}\n\n@media screen and (max-width: 990px) {\n    .app__newsletter-input {\n        flex-direction: column;\n        width: 100%;\n    }\n\n    .app__newsletter-input input {\n        margin: 0 0 2rem 0;\n        width: 100%;\n    }\n}\n\n@media screen and (max-width: 650px) {\n    .app__newsletter {\n        padding: 2rem 0;\n        border: none;\n    }\n}\n\n@media screen and (max-width: 300px) {\n    .app__newsletter-heading h1 {\n        font-size: 32px;\n        line-height: 50px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
